import { motion } from "framer-motion"

export function CustomSlideshow({ children }) {
    return (
        <motion.div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "fit-content",
            }}
        >
            {children}
        </motion.div>
    )
}
